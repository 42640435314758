import React, {useEffect, useReducer, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useParams} from 'react-router'
import {useRequest} from '../../utils/hooks/useRequest'
import {Filter, FilterCondition, FilterTemplate} from '@contractool/schema'
import {Modal} from '../../components/Modal'
import {Button} from '../../components/Button'
import {Field} from '../../components/Field'
import {TextInput} from '../../components/TextInput'
import {http} from '../../utils/http'
import {useToasts} from 'hooks'
import {filterReducer} from '../../utils/filterReducer'
import {translate} from 'utils/translations'
import {Condition} from './FilterNew'

export function FilterEdit({
  filters,
  heading,
  onSubmit
}: {
    filters: Filter[];
    heading: string;
    onSubmit: () => void;
}) {
  const history = useHistory()
  const {id} = useParams<{id?: string}>()
  const {error, success} = useToasts()

  const [errors, setErrors] = useState<{index: number; field: string}[]>([])
  const [filterTemplates] = useRequest<FilterTemplate[]>('/api/project-filter-templates', [])
  const [state, dispatch] = useReducer(filterReducer, [])
  const [title, setTitle] = useState<string>()
  const listOfConditions = filterTemplates.map((template: FilterTemplate) => {
    return {label: template.label, value: template.name}
  })

  useEffect(() => {
    dispatch({
      type: 'LOAD_CONDITIONS',
      conditions: filters.find((filter) => filter.id === Number(id))?.conditions
    })
    setTitle(filters.find((filter) => filter.id === Number(id))?.title)
  }, [filters, id])

  const close = () => {
    history.goBack()
  }
  const handelError = (err: any) => {
    const errorsToObject = () => {
      const split = Object.keys(err.response.data.errors)
        .map((item) => {
          return item.split('.')
        })
        .filter((item) => !item.includes('title'))

      return split.map((item) => {
        return {index: Number(item[1]), field: item[2]}
      })
    }
    setErrors(errorsToObject())
    error(`${translate('The given data was invalid')}.`)
  }
  const submit = (message: string) => {
    http.put(`/api/project-filters/${id}`, {
      title,
      conditions: state.map((item: FilterCondition) => {
        return {
          operation: item.operation,
          subject: item.subject,
          value: item.value
        }
      })
    })
      .then(() => {
        onSubmit()
        close()
        success(message)
      })
      .catch(handelError)
  }

  return (
    <Modal heading={heading} isOpen={true} onClose={close} size="regular">
      <div>
        <Field name="category_name" label={translate('Category name')} className="mb-6">
          <TextInput
            name="category_name"
            placeholder={translate('Enter category name')}
            value={title}
            onChange={(title) => setTitle(title)}
            maxLength={32}
            required={true}
          />
        </Field>
        {state &&
                    state.map((condition: FilterCondition, index) => {
                      return (
                        <div key={condition.id + condition.subject}>
                          <div className="text-gray-600">
                            {index ? translate('and') : translate('Condition')}
                          </div>
                          <Condition
                            errors={errors.filter((error) => error.index === index)}
                            onChange={(field) => {
                              setErrors(
                                errors.filter(
                                  (error) =>
                                    !(
                                      error.field === field &&
                                                        error.index === index
                                    )
                                )
                              )
                            }}
                            listOfConditions={listOfConditions}
                            condition={condition}
                            filterTemplates={filterTemplates}
                            dispatch={dispatch}
                          />
                        </div>
                      )
                    })}
        <div className="flex items-center mt-8 text-gray-600">
          <Button
            onClick={() => {
              dispatch({type: 'ADD_CONDITION'})
            }}
            color="white"
            size="small"
            radius="full"
            icon="add"
            className="mr-4"
          />
          {translate('Add condition')}
        </div>
      </div>
      <div className="flex mt-16 justify-between">
        <Button color="white" onClick={close}>
          {translate('Cancel')}
        </Button>
        <Button
          className="bg-blue-700"
          onClick={() => submit('Category successfully updated.')}
        >
          {translate('Save Changes')}
        </Button>
      </div>
    </Modal>
  )
}
