import React, {FC, useState} from 'react'
import {Form} from '../../components/Form'
import {http} from '../../utils/http'
import {translate} from 'utils/translations'
import {useParams} from 'react-router-dom'
import {Layout} from './Layout'
import {Button} from '../../components/Button'
import {Message} from '../../components/Message'

export const ResetPasswordPage: FC = () => {
  let {email, token} = useParams<{email?: string, token?: string}>()

  const [message, setMessage] = useState('')

  return (
    <Layout>
      <h1 className="text-3xl leading-tight font-semibold select-none text-gray-800">
        {translate('Update password')}
      </h1>

      <p className="mt-6 mb-30 text-gray-600">
        {translate(
          'Set a new password. Use at least 8 characters, try to use numbers, uppercase letters or special characters.'
        )}
      </p>

      {message ? (
        <>
          <Message.Success>{message}</Message.Success>

          <Button.Link to="/login" className="mt-30 w-full">
            {translate('Sign in')}
          </Button.Link>
        </>
      ) : (
        <Form
          initialValues={{
            password: '',
            password_confirmation: '',
            email: email,
            token: token
          }}
          onSubmit={(values) => http.post('/api/auth/password/reset', values)}
          onSuccess={(response: string) => setMessage(response)}
          allowPristineSubmission={true}
          guard={false}
        >
          <Form.TextInput
            type="password"
            name="password"
            label={translate('New password')}
            className="mb-6"
          />

          <Form.TextInput
            type="password"
            name="password_confirmation"
            label={translate('Confirm password')}
          />

          <Form.Submit className="w-full mt-30">
            {translate('Update password')}
          </Form.Submit>
        </Form>
      )}
    </Layout>
  )
}
