import {Project} from '@contractool/schema'
import React, {FC} from 'react'
import {Link, useParams} from 'react-router-dom'

import {Modal} from 'components/Modal'
import {Icon} from 'components/Icon'
import {Button} from 'components/Button'
import {translate} from 'utils/translations'

interface ProjectRedFlagWidgetProps {
  project:Project
}

export const ProjectRedFlagWidget: FC<ProjectRedFlagWidgetProps> = ({project}) => {
  let textColor = 'white'
  if (project.fields_human.risk_profile === 'High') {
    textColor = 'red'
  }
  if (project.fields_human.risk_profile === 'Medium') {
    textColor = 'orange'
  }
  if (project.fields_human.risk_profile === 'Small') {
    textColor = 'gray'
  }
  if (!project.fields_human.risk_profile) {
    return <></>
  }

  return (
    <>
      <label className="block leading-none select-none mt-5 text-gray-600 mb-4">
        { translate('Risk Profile') }
      </label>
      <div className='flex my-6 text-xl'>
        <div className='rounded-full mr-5 my-auto' style={{height:'10px',width:'10px', backgroundColor: textColor}}/>
        <h3 className='font-bolder'>{project.fields_human.risk_profile}</h3>
      </div>

      <label className="block leading-none select-none text-gray-600 mt-5 mb-5">{ `${project.extra.red_flags.length} ${translate('Reasons')} `}</label>
      {project.extra.red_flags.map((flag:any) => {
        let bgColor = '#ffe3e3'
        let textColor = '#fa5252'

        if (flag.risk === 'medium') {
          textColor = 'orange'
          bgColor = '#ff980036'
        }
        if (flag.risk === 'small') {
          textColor = 'gray'
          bgColor = 'lightgray'
        }

        return <Link
          to={`/projects/${project.id}/contract/red-flags/${flag.word}`}
          key={flag.word}
        >
          <div className="flex justify-between p-3 my-3 rounded border-red-200 font-bold bolder" style={{color: textColor, backgroundColor: bgColor}}>
            <div className='flex'>
              <span className='mx-2'>{flag.word}</span>
              <Icon name='help' size={6} className='my-auto'/>
            </div>
            <Icon name='arrow_forward' size={6} />
          </div>
        </Link>
      })}
    </>
  )}

interface ProjectRedFlagWidgetDetailProps {
  project: Project
  onClose: () =>void
}

export const ProjectRedFlagWidgetDetail: FC<ProjectRedFlagWidgetDetailProps> = ({onClose, project}) => {
  const {flag} = useParams() as {flag: string}

  const contract = project.attachments.find(attachment => attachment.id === project.document_id)
  const plainText = contract?.versions[contract.versions.length - 1]
    .file
    .plain_text
    ?.replace(new RegExp(flag, 'ig'), `<span class="red-flag-highlighted">${flag}</span>`)
    || ''

  return (
    <Modal heading={translate(flag)} onClose={onClose}>
      <div className='py-5'>
        <div dangerouslySetInnerHTML={{__html: plainText}} />
      </div>
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
