import {ClauseCategory} from '@contractool/schema'
import {useStatefulResource} from '@rest-hooks/legacy'
import React, {FC}  from 'react'
import {useFetcher, useInvalidator} from 'rest-hooks'
import {useHistory} from 'react-router-dom'

import {Page} from 'components'
import Search from 'components/Search'
import {Tab, Tabs} from 'components/Tabs'
import {TextInput} from 'components/TextInput'
import {useRequest} from 'hooks/useRequest'
import {ClauseResource, TemplateResource} from 'resources'
import {translate} from 'utils/translations'
import {SortDirection} from 'views/template-clause/types'
import {Button} from 'components/Button'
import {DndProvider, useDrop} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {useParams} from 'react-router-dom'
import {http} from 'utils/http'
import TemplateClauseItem from './TemplateClauseItem'
import ClauseItem from './ClauseItem'
import templateClauseReducer from './reducer'

const clausesSortableColumns = ['name', 'content'] as const
export const clausesTpTypes = {clause:'Clause',tpClause:'TpClause'}
type ClausesSortableColumn = typeof clausesSortableColumns[number]

const TemplateEditor: FC = () => {
  const [clauseCategories] = useRequest<ClauseCategory[]>('/api/clause-categories', [])
  const {templateId} = useParams() as {templateId: string}
  const {data: template} = useStatefulResource(TemplateResource.detail(), {
    id: templateId
  })
  const [searchString, setSearchString] = React.useState('')

  const updateSearchString = React.useCallback((query: string) => {
    setSearchString(query)
  }, [])

  const updateSelectedTab = React.useCallback((category_id:string) => {
    setClausesCategoryFilter(category_id)
  }, [])

  const clearSearch = React.useCallback(() => {
    updateSearchString('')
  }, [updateSearchString])

  const [currentPage] = React.useState(1)
  const [clausesSortBy] = React.useState<ClausesSortableColumn>(clausesSortableColumns[0])
  const [clausesSortDirection] = React.useState<SortDirection>('asc')
  const [clauseCategoryFilter, setClausesCategoryFilter] = React.useState('0')

  const {data: {data: clauses}} = useStatefulResource(ClauseResource.list(), {
    keyword: searchString,
    page: currentPage,
    sortBy: clausesSortBy,
    sortDirection: clausesSortDirection,
    category_id: clauseCategoryFilter,
    per_page:100
  })

  const history = useHistory()
  const goToTemplateClauses = React.useCallback(() => {
    history.push('/template-clause')
  }, [history])

  return template ? (
    <DndProvider backend={HTML5Backend}>
      <Page
        heading={<Heading template={template} />}
        right={<Button color='blue' className='mx-3' size='small' onClick={goToTemplateClauses}>{translate('Back')}</Button>}
      >
        <div className="flex flex-1">
          <div className="w-7/12 p-10 pt-8 bg-gray-000 px-4">
            <div>
              {template.id && <ClauseTemplatePage template={template}/>}
            </div>
          </div>
          <div className="w-5/12 p-10 pt-8 border-t border-gray-200 ">
            <h2>{translate('Clauses')}</h2>
            <div className="flex-1 pt-3">
              <Search
                value={searchString}
                onChange={updateSearchString}
                onClear={clearSearch}
                placeholder={`${translate('Search')}...`}
              />
            </div>
            <Tabs selected={'0'} onSelect={updateSelectedTab}>
              <Tab heading={translate('All')} name={'0'} key={0}>
                {clauses?.map((clause) => <ClauseItem key={clause.id} clause={clause} />)}
              </Tab>
              {clauseCategories.map((category) => (
                <Tab key={`${category.id}`} name={`${category.id}`} heading={translate(category.name)} >
                  {clauses?.map((clause) => <ClauseItem key={clause.id} clause={clause} />)}
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </Page>
    </DndProvider>
  ) : null
}

const input = {
  className: 'leading-tighter'
}

const Heading: React.FC<{
  template: TemplateResource
}> = ({template}) => {
  const {name} = template
  const updateTemplate = useFetcher(TemplateResource.update())
  const invalidateTemplates = useInvalidator(TemplateResource.list())

  const handleHeadingInput = React.useCallback(async (updatedHeading) => {
    await updateTemplate(template, {
      name: updatedHeading
    })
    invalidateTemplates({})
  }, [template, invalidateTemplates, updateTemplate])

  const [editing, setEditing] = React.useState(false)
  const [headingModel, setHeadingModel] = React.useState<string>(name ?? 'Template Editor')

  const handleClick = () => {
    setEditing(true)
  }

  const handleBlur = async () => {
    await handleHeadingInput(headingModel)
    setEditing(false)
  }

  return (
    editing ? (
      <TextInput
        autoFocus
        input={input}
        name="title"
        onBlur={handleBlur}
        onChange={setHeadingModel}
        value={headingModel}
      />
    ) : <div className="border-b border-gray-200 leading-tighter py-5" onClick={handleClick}>{headingModel}</div>
  )
}

const ClauseTemplatePage: FC<{template: TemplateResource}> = ({template}) => {
  const [tpClauses, setTpClauses] = React.useReducer(templateClauseReducer, template.clauses)
  const clauseToDelete = React.useRef(null)

  React.useEffect(() => {
    http.put(`/api/templates/${template.id}/clauses`, {clauses_to_update:tpClauses, clauses_to_delete:clauseToDelete.current})
    clauseToDelete.current = null
  },[template, tpClauses])

  const addTpClauses = React.useCallback((clause:any, index:number|undefined)=>{
    if (!tpClauses.find(cl => cl.id === clause.id)) {
      if (index === undefined || typeof index == 'undefined') {
        setTpClauses({type:'add', payload:clause})
      } else {
        setTpClauses({type:'add-in-position', payload:{clause, index}})
      }
    }
  },[tpClauses])

  const [, drop] = useDrop(
    () => ({
      accept: clausesTpTypes.clause ,
      drop: ({clause}:{clause:ClauseResource}) => addTpClauses(clause, undefined),
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }),
    [addTpClauses]
  )

  const moveClause = React.useCallback((id:number, afterId:number):void => {
    const clause = tpClauses[id]
    setTpClauses({type:'move', payload:{id, afterId, clause}})
  }, [tpClauses])

  const deleteTpClause = (tpClauseIndex:number):void => {
    const delClause = tpClauses[tpClauseIndex]
    clauseToDelete.current = delClause.id
    setTpClauses({type:'delete', payload:{index:tpClauseIndex}})
  }

  return (<div ref={drop} className='p-5 bg-white'>
    <div className='py-6'>
      Template Content

      {tpClauses.map((tpClause, index) => {
        return (
          <div key={`tp-clause-${tpClause.id}`} >
            <TemplateClauseItem  clause={tpClause} idIndex={index} id={tpClause.id} moveTpClause={moveClause} deleteTpClause={deleteTpClause} addTpClause={addTpClauses} />
          </div>
        )
      })}
    </div>
  </div>
  )
}


export default TemplateEditor
