import {RedFlag} from '@contractool/schema'
import React, {useCallback, useMemo} from 'react'
import {useParams, useHistory, Redirect} from 'react-router'

import {Button} from 'components/Button'
import {Icon} from 'components/Icon'
import {Menu, MenuItem} from 'components/Menu'
import {Modal} from 'components/Modal'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {tag} from 'views/settings/red-flags/constants'
import Form from './Form'
import {Form as CommonForm} from 'components/Form'

export function Edit({redFlags, onUpdate}: {
  redFlags: RedFlag[]
  onUpdate: () => void
}) {
  const history = useHistory()
  const {id: idParam} = useParams<{id: string}>()
  const id = parseInt(idParam)
  const index = useMemo(() => redFlags.findIndex(({id: flagId}) => id === flagId), [redFlags, id])

  const goToSettingsPage = useCallback(() => history.push(`/settings/${tag}`), [history])
  const {success} = useToasts()

  const deleteFlag = useCallback(() => {
    http.put(`/api/settings/${tag}`, {
      value: redFlags.splice(index, 1)
    }).then(() => {
      onUpdate()
      success('The red flag has been deleted.', {
        position: 'center'
      })
      goToSettingsPage()
    })
  }, [redFlags, index, goToSettingsPage, onUpdate, success])

  const redFlag = redFlags[index]

  return redFlag ? (
    <Modal
      heading={translate('Red Flag Detail')}
      onClose={goToSettingsPage}
      contentClassName="h-124"
      corner={
        <Menu
          handle={({toggleMenu}) => (
            <div onClick={toggleMenu} className="flex items-center cursor-pointer">
              <Icon name="more_horiz" className="text-gray-600 cursor-pointer" size={6} />
            </div>
          )}
        >
          <MenuItem icon="remove_circle" onClick={deleteFlag}>
            {translate('Delete')}
          </MenuItem>
        </Menu>
      }
    >
      <CommonForm
        initialValues={redFlag}
        onSubmit={(flag) => {
          redFlags[index] = flag

          return http.put(`/api/settings/${tag}`, {
            value: redFlags
          })
        }}
        onSuccess={() => {
          onUpdate()
          success('Red flags has been updated.', {
            position: 'center'
          })
          goToSettingsPage()
        }}
      >
        <Form redFlag={redFlag} />

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={history.goBack}>
            {translate('Cancel')}
          </Button>

          <CommonForm.Submit>{translate('Save Changes')}</CommonForm.Submit>
        </Modal.Footer>
      </CommonForm>
    </Modal>
  ) : <Redirect to="/settings" />
}
