import {FieldTemplate, Project, ProjectCategory} from '@contractool/schema'
import React, {FC, useContext, useEffect} from 'react'

import {AppContext} from 'contexts'
import {CustomField} from 'components'
import {Form as CommonForm, FormContext} from 'components/Form'
import {Immutable as ImmutableTitle} from 'components/form/ProjectTitle'
import {Mutable as MutableTitle} from 'components/form/ProjectTitle'
import SupplierSelect from 'components/form/SupplierSelect'
import {useRequest} from 'hooks/useRequest'
import {getOptions} from 'utils/form'
import {translate} from 'utils/translations'
import ProjectFormContext from 'views/project/ProjectFormContext'

const Form: FC<{ project: Project }> = ({project}) => {
  const {create, supplierTitle} = React.useContext(ProjectFormContext)
  const [categories] = useRequest<ProjectCategory[]>('/api/project-categories', [])
  const categoriesAsOptions = getOptions(categories)

  const {config} = useContext(AppContext)

  const fields = config.fields
    ? config.fields.filter((f: FieldTemplate) => !f.hidden && f.workflow === project.workflow)
    : []

  let groups: any = {}
  for (let f of fields) {
    if (f.group !== '') {
      groups[f.group] = f.group
    }
  }

  const formContext = useContext(FormContext)

  useEffect(() => {
    if (!create) {
      return
    }
    const parts = []
    const {values} = formContext
    if (values.supplier_id) {
      parts.push(supplierTitle)
    }
    if (values.fields.request_type) {
      parts.push(formContext.values.fields.request_type)
    }

    const title = parts.join(' ')

    if (values.title !== title) {
      formContext.handleChange('title', title)
    }
  }, [formContext, supplierTitle, create])

  return project ? (
    <>
      {config.roles && fields && (
        <>
          {create ? (
            <ImmutableTitle className="mb-8"/>
          ) : (
            <MutableTitle className="mb-8"/>
          )}
          <div className="divide-y divide-gray-100">
            <div className="py-8 w-full">
              <CommonForm.Dropdown
                name="category_id"
                className="mb-6"
                label={translate('Spend Category')}
                options={categoriesAsOptions}
                required={true}
              />
              <SupplierSelect required/>
              {fields
                .filter((f: FieldTemplate) => f.group === '')
                .map((field: FieldTemplate) => (
                  <CustomField
                    key={`${field.name}`}
                    field={field}
                    option="fields."
                    className="mt-6"
                    required={field.required}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  ) : null
}

export default Form
