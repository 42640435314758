import React from 'react'
import {Tab, Tabs} from 'components/Tabs'
import ProjectFieldList from './ProjectFieldList'
import {Tab as TabInterface} from '../../../interfaces'

const MemberModalTabs: React.FC<{tabs: TabInterface[]}> = ({tabs}) => (
  <Tabs className="h-27">
    {tabs.map((tab: TabInterface, index: number) => (
      <Tab key={index} name={tab.name} heading={tab.name}>
        <ProjectFieldList fields={tab.fields} />
      </Tab>
    ))}
  </Tabs>
)


const PureMemberModalTabs = React.memo(MemberModalTabs)

export default PureMemberModalTabs
