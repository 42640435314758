import {FilterCondition, FilterTemplate, RedFlag, Role, UserGroup} from '@contractool/schema'
import React, {FC, useContext, useEffect, useMemo, useReducer, useState} from 'react'

import {Button} from 'components/Button'
import {Option} from 'components/Dropdown'
import {Form as CommonForm, FormContext} from 'components/Form'
import {AppContext} from 'contexts'
import {useRequest} from 'hooks/useRequest'
import {usePrevious} from 'hooks'
import {filterReducer} from 'utils/filterReducer'
import {translate} from 'utils/translations'
import {Condition} from 'views/projects/FilterNew'

const riskOptions = [
  {label: 'Small', value: 'small'},
  {label: 'Medium', value: 'medium'},
  {label: 'High', value: 'high'}
]

interface IRedFlagFormProps {
  redFlag: RedFlag
}

const Form: FC<IRedFlagFormProps> = ({
  redFlag
}) => {
  const {config} = useContext(AppContext)

  const roleOptions: Option<string>[] = useMemo(() =>
    config.roles.map((role: Role) => ({
      value: role.key,
      label: role.label
    })), [config])

  const groupOptions = useMemo(() =>
    config.user_groups.map((group: UserGroup) => ({
      label: group.label,
      value: group.key
    })), [config])

  const [filterTemplates] = useRequest<FilterTemplate[]>('/api/project-filter-templates', [])
  const listOfConditions = filterTemplates.map((template: FilterTemplate) => {
    return {label: template.label, value: template.name}
  })

  const [errors, setErrors] = useState<{index: number; field: string}[]>([])

  const [conditions, setConditions] = useReducer(filterReducer, redFlag?.conditions ?? [])
  const previousConditions = usePrevious(conditions)
  const serializedConditions = JSON.stringify(conditions)

  const {handleChange} = useContext(FormContext)
  useEffect(() => {
    if (
      typeof previousConditions !== 'undefined' &&
      JSON.stringify(previousConditions) !== JSON.stringify(conditions)
    ) {
      handleChange('conditions updated', JSON.parse(serializedConditions))
    }
    // eslint-disable-next-line
  }, [previousConditions, conditions])

  return (
    <>
      <div className="flex mb-6 -mx-2">
        <div className="w-2/3 mx-2">
          <CommonForm.TextInput
            name="input"
            placeholder={translate('Name')}
            autoFocus
          />
        </div>
        <div className="w-1/3 mx-2">
          <CommonForm.Dropdown
            name="risk"
            placeholder={translate('Risk')}
            options={riskOptions}
          />
        </div>
      </div>
      <div className="flex mb-6 -mx-2 border-b">
        <div className="w-1/3 mx-2">
          <CommonForm.Dropdown
            name="roleKey"
            options={roleOptions}
            placeholder={translate('Select role')}
          />
        </div>
        <div className="w-1/3 mx-2 pb-20">
          <CommonForm.Dropdown
            name="groupKey"
            options={groupOptions}
            placeholder={translate('Users Group')}
          />
        </div>
        <div className="w-1/3 mx-2">
          <CommonForm.Dropdown
            name="userId"
            api="api/users"
            apiParams={{phrase: 'name'}}
            placeholder={`${translate('User')}`}
            autocomplete
          />
        </div>
      </div>
      {conditions.map((condition: FilterCondition, index) => (
        <div key={condition.id + condition.subject}>
          <div className="text-gray-600">{index ? 'and' : 'Condition'}</div>
          <Condition
            errors={errors.filter((error) => error.index === index)}
            onChange={(field) => {
              setErrors(
                errors.filter(
                  (error) =>
                    !(error.field === field && error.index === index)
                )
              )
            }}
            listOfConditions={listOfConditions}
            condition={condition}
            filterTemplates={filterTemplates}
            dispatch={setConditions}
          />
        </div>
      ))}
      <div className="flex items-center my-8 text-gray-600">
        <Button
          onClick={() => setConditions({type: 'ADD_CONDITION'})}
          color="white"
          size="small"
          radius="full"
          icon="add"
          className="mr-4"
        />
        {translate('Add condition')}
      </div>
    </>
  )
}

export default Form
