import React, {ReactElement} from 'react'

import {Icon, Icons, Size} from './Icon'

export type Color =
    | 'orange'
    | 'green'
    | 'red'
    | 'blue'
    | 'violet'
    | 'cyan'
    | 'pink'
    | 'yellow'
    | 'teal'
    | 'lightgray';

export function IconCircle({
  name,
  color,
  className = '',
  circleSize,
  iconSize
}: {
    name: Icons;
    color?: Color;
    className?: string;
    circleSize: Size;
    iconSize: Size;
}): ReactElement {
  className = className + ' flex justify-center rounded-full ' + sizeStyles(circleSize)

  switch (color) {
    case 'orange':
      return (
        <div className={`${className} text-orange-700 bg-orange-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'green':
      return (
        <div className={`${className} text-green-700 bg-green-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'red':
      return (
        <div className={`${className} text-red-700 bg-red-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'blue':
      return (
        <div className={`${className} text-blue-700 bg-blue-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'violet':
      return (
        <div className={`${className} text-violet-700 bg-violet-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'cyan':
      return (
        <div className={`${className} text-cyan-700 bg-cyan-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'pink':
      return (
        <div className={`${className} text-pink-700 bg-pink-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'yellow':
      return (
        <div className={`${className} text-yellow-700 bg-yellow-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'teal':
      return (
        <div className={`${className} text-teal-700 bg-teal-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    case 'lightgray':
      return (
        <div className={`${className} text-gray-500 bg-gray-000`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
    default:
      return (
        <div className={`text-gray-700 bg-gray-000 ${className}`}>
          <Icon name={name} className="self-center" size={iconSize} />
        </div>
      )
  }
}

function sizeStyles(s: Size): string {
  return `w-${s} h-${s}`
}
