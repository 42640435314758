import * as React from 'react'

import {FormContext} from 'components/Form'
import {Option} from 'components/Dropdown'

const fieldDependsOnOtherThan = (Component: React.ComponentType<any>) =>
  (props: any) => {
    const {field} = props
    const formContext = React.useContext(FormContext)
    if (field.showOnlyIf) {
      let relatedValue = formContext.values[field.showOnlyIf.propertyName]
      if (relatedValue === true) {
        relatedValue = '1'
      }
      if (relatedValue === false) {
        relatedValue = '0'
      }
      if (!field.showOnlyIf.value.includes('' + relatedValue)) {
        return null
      }
    }

    if (field.filterOptions) {

      let relatedValue = formContext.values.fields[field.filterOptions.propertyName]

      if (typeof relatedValue === 'undefined') {
        relatedValue = formContext.values[field.filterOptions.propertyName]
      }

      if (relatedValue === true) {
        relatedValue = '1'
      }
      if (relatedValue === false) {
        relatedValue = '0'
      }
      if ('' + relatedValue in field.filterOptions.conditions) {
        field.values = field.filterOptions.conditions[relatedValue] as Option<string>[]
      } else {
        field.values = []
      }
    }

    return <Component {...props} />
  }

export default fieldDependsOnOtherThan
