import React, {useCallback, useContext} from 'react'
import {useHistory} from 'react-router'
import {Form} from '../../components/Form'
import {http} from '../../utils/http'
import {translate} from 'utils/translations'
import {AuthContext} from 'contexts'
import {User} from '@contractool/schema'
import {Layout} from './Layout'
import {Link} from 'react-router-dom'
import {useRequest} from 'hooks/useRequest'
import {Button} from 'components/Button'

const initialValues = {
  email: '',
  password: ''
}

export function LoginPage() {
  const history = useHistory()
  const {login} = useContext(AuthContext)

  const attemptLogin = useCallback((values) => {
    return http.post('/api/auth/login', values)
  }, [])

  const [samlConfig] = useRequest<any>(
    '/api/saml_config',
    undefined
  )

  const onSuccess = useCallback(
    ({user}: { user: User }) => {
      login(user)
      history.push('/')
    },
    [history, login]
  )
  console.log('samlConfig', samlConfig)
  if (!samlConfig) {
    return <></>
  }

  return (
    <Layout>
      <h1 className="text-3xl leading-tight font-semibold select-none mb-30 text-gray-800">
        {translate('Sign in to Cequence')}
      </h1>

      <Form
        initialValues={initialValues}
        submitOnEnter={true}
        onSubmit={attemptLogin}
        onSuccess={onSuccess}
        allowPristineSubmission={true}
        guard={false}
      >
        <Form.TextInput
          name="email"
          label="Email"
          placeholder="your@email.com"
          className="mb-6"
        />

        <Form.TextInput
          type="password"
          name="password"
          label={translate('Password')}
          className="mb-4"
        />

        <Link to="/password/forgot" className="text-gray-600 select-none">
          {translate('Forgot password?')}
        </Link>

        {samlConfig.saml_login_type === 'only_login' &&
                <Form.Submit className="w-full mt-30">{translate('Sign In')}</Form.Submit>}
        {samlConfig.saml_login_type === 'sso_and_login' && <div className="flex items-center mt-30">
          <Form.Submit className="w-full  mr-2 flex-1"  >{translate('Sign In')}</Form.Submit>
          <Button color="white" className="flex-1" onClick={() => {
            window.location.href = `/saml2/${samlConfig.saml_login}/login`
          }
          }>{translate('Login using SSO')}</Button>
        </div>}
      </Form>
    </Layout>
  )
}
