import React, {FC, KeyboardEvent} from 'react'
import {Icons, Icon} from './Icon'

export type Type = 'text' | 'email' | 'password' | 'tel';

const defaultStyle = {
  backgroundColor: 'transparent'
}

export const TextInput: FC<{
    name?: string;
    input?: any;
    type?: Type;
    value?: string;
    icon?: Icons;
    defaultValue?: string;
    placeholder?: string;
    hasError?: boolean;
    required?: boolean;
    autoComplete?: Autocomplete;
    autoFocus?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    maxLength?: number;
    onChange?: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}> = ({
  name,
  input,
  type = 'text',
  placeholder,
  value,
  icon,
  defaultValue,
  onChange,
  required,
  autoComplete = 'off',
  autoFocus,
  hasError = false,
  disabled = false,
  readOnly = false,
  maxLength,

  onFocus,
  onBlur,
  onKeyDown,
  ...restProps
}) => {
  return (
    <div className="relative w-full">
      {icon && (
        <Icon name={icon} size={5} className="absolute left-0 top-0 h-full text-gray-600" />
      )}
      <input
        id={name}
        name={name}
        type={type}
        placeholder={
          placeholder ? placeholder : type === 'password' ? '••••••••' : placeholder
        }
        value={value || defaultValue || ''}
        onChange={(e) => onChange && onChange(e.target.value)}
        required={required}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        readOnly={readOnly}
        className={`py-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 ${
          hasError ? 'border-red-700' : 'border-gray-200 focus:border-blue-700'
        } ${icon ? 'pl-7' : ''} ${input && input.className ? input.className : ''}`}
        style={{
          ...defaultStyle,
          ...(input && input.style ? input.style : null)
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        {...restProps}
      />
    </div>
  )
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
export type Autocomplete =
    | 'off'
    | 'on'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension'
    | 'impp'
    | 'url'
    | 'photo';
