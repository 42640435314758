import React, {FC, useContext, useState} from 'react'
import {Project, Role, TeamMember, User} from '@contractool/schema'
import {Modal} from '../../components/Modal'
import {Button} from '../../components/Button'
import {Menu, MenuItem} from '../../components/Menu'
import {Dropdown, Option} from '../../components/Dropdown'
import {Avatar} from '../../components/Avatar'
import {useToasts} from 'hooks'
import {AppContext} from 'contexts'
import {http} from '../../utils/http'
import {translate} from 'utils/translations'
import {useSmallLoader} from 'components/Loader'
import {permissionRequest} from 'utils/wildcard'

export const ProjectTeamModal: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
    members: TeamMember[];
}> = ({project, onUpdate, onClose, members}) => {
  const {success, error} = useToasts()

  const smallLoader = useSmallLoader()

  const [membersInList, setMembersInList] = useState(members)
  const [addingUser, setAddingUser] = useState(false)
  const [assigneeUserId, setAssigneeUserId] = useState(project.team.assignee?.id)
  const [memberAdding, setMemberAdding] = useState('')

  const newMemberAdded = (userId: string) => {
    //TODO nastavit focus do dropdownu pri add user
    http.get<User>(`api/users/${userId}`).then((data) => {
      let id = parseInt(userId)
      console.log('new_member', userId, data, membersInList)

      setMembersInList([
        ...membersInList,
        {
          id: 9999999,
          note: '',
          role: '',
          user: data.data,
          user_id: id,
          deleted: false
        }
      ])
      setMemberAdding('')
      setAddingUser(false)
    })
  }

  const deleteMember = (member: TeamMember) => {
    if (member.user_id === assigneeUserId) {
      error(`${translate('Cannot remove assignee')}`)

      return
    }
    smallLoader.start('Deleting...')
    http.delete(`/api/projects/${project.id}/team/${member.id}`)
      .then(() => {
        onUpdate()
        smallLoader.stop()
        success(`${translate('Member has been deleted')}`)
        for (let i in membersInList) {
          let m = membersInList[i]
          console.log('m.id === member.id', m, member, m.id === member.id)
          if (m.id === member.id) {
            m.deleted = true
          }
          membersInList[i] = m
        }
        console.log('membersInList', membersInList)
        setMembersInList(JSON.parse(JSON.stringify(membersInList)))
      })
  }

  const onRoleChange = (roleKey: string, member: TeamMember) => {
    if (member.id === 9999999) {
      let wrong = false
      for (let i in membersInList) {
        let m = membersInList[i]
        if (m.role === roleKey && m.user_id === member.user_id) {
          error('This user is already in this role')
          wrong = true
          m.deleted = true
          m.id = Math.random()
        }
        membersInList[i] = m
      }

      if (wrong) {
        setMembersInList(JSON.parse(JSON.stringify(membersInList)))

        return
      }

      member.id = 0
      member.role = roleKey
      smallLoader.start('Adding member...')
      http.post(`/api/projects/${project.id}/team/add`, member)
        .then((response: any) => {
          onUpdate()
          for (let i in membersInList) {
            let m = membersInList[i]
            console.log('m.id === member.id2', m, member, m.id === member.id)
            if (!m.id) {
              m = response.data
            }
            membersInList[i] = m
          }
          console.log('onRoleAdd', membersInList)
          setMembersInList(JSON.parse(JSON.stringify(membersInList)))


          success(`${translate('Member has been added')}`)
          smallLoader.stop()
        })

      return
    }
    for (let i in membersInList) {
      let m = membersInList[i]
      console.log('m.id === member.id', m, member, m.id === member.id)
      smallLoader.start('Changing member...')
      if (m.id === member.id) {
        m.role = roleKey
        http.post(`/api/projects/${project.id}/team/changeRole/${member.id}`, member)
          .then(() => {
            onUpdate()
            success(`${translate('Member has been updated')}`)
            smallLoader.stop()
          })
      }
      membersInList[i] = m
    }
    console.log('onRoleChange', membersInList)
    setMembersInList(JSON.parse(JSON.stringify(membersInList)))
  }

  const assign = (member: TeamMember) => {
    smallLoader.start(`Assigning to ${member.user.name}...`)
    http.put(`/api/projects/${project.id}/team/assignee/`, {
      assignee_user_id: member.user_id
    })
      .then(() => {
        onUpdate()
        success(`${translate('Asignee has been changed')}`)
        setAssigneeUserId(member.user_id)
        smallLoader.stop()
      })
  }

  return (
    <Modal heading={translate('Team')} onClose={onClose}>
      {membersInList
        .filter((m) => !m.deleted)
        .map((member) => (
          <div
            key={member.id ? member.id : 999999}
            title={member.user.name}
            className="flex items-center my-5 justify-between"
          >
            <Avatar user={member.user} className="w-13 h-13 mr-5"/>
            <div className="w-2/5">
              {member.user.name}
              {assigneeUserId === member.user.id ? (
                <span className="text-green-900 bg-green-100 p-1 rounded mx-5">
                  {translate('Assignee')}
                </span>
              ) : (
                ''
              )}
            </div>
            <RoleSelect member={member} onChange={onRoleChange} project={project}/>
            <Menu handle="more_vert" autoClose={true}>
              <MenuItem
                icon="delete"
                onClick={() => {
                  deleteMember(member)
                }}
              >
                {translate('Delete')}
              </MenuItem>
              {permissionRequest(project.can, 'project.assign') &&
                            <MenuItem
                              icon="person"
                              onClick={() => {
                                assign(member)
                              }}
                            >
                              {translate('Assign')}
                            </MenuItem>}
            </Menu>
          </div>
        ))}
      {!addingUser ? (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setAddingUser(true)
          }}
        >
          <Button color="white" size="small" radius="full" icon="add"></Button>
          <span className="text-gray-500 ml-5">{translate('Add person')}</span>
        </div>
      ) : (
        <Dropdown
          name={'new_member'}
          value={memberAdding}
          api="api/users"
          placeholder={`${translate('Start typing')}...`}
          onChange={newMemberAdded}
          hasError={false}
          autocomplete={true}
          autoFocus={true}
        />
      )}
      <div className="h-25"></div>
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const RoleSelect: FC<{
    member: TeamMember;
    onChange: (roleKey: string, member: TeamMember) => void;
    project: Project;
}> = ({member, onChange, project}) => {
  const {config} = useContext(AppContext)

  console.log('teamconfig', config)
  let role = config.roles.filter((role: Role) => {
    return role.key === member.role
  })
  let roleSelected = ''
  if (role.length > 0) {
    roleSelected = role[0].key
  }
  console.log('role', role)
  var roleOptions: Option<any>[] = config.roles
    .filter((role: Role) => {
      return role.key !== 'assignee' && role.workflow === project.workflow
    })
    .map((role: Role) => {
      return {
        value: role.key,
        label: role.label
      }
    })
    // roleOptions.unshift({
    //     value: '',
    //     label: 'Select role'
    // });
  console.log('roleOptions', roleOptions)

  return (
    <Dropdown
      name={`member${member.id}`}
      value={roleSelected}
      options={roleOptions}
      onChange={(roleKey) => {
        onChange(roleKey + '', member)
      }}
      hasError={false}
      autocomplete={false}
      placeholder={translate('Select role')}
    />
  )
}
