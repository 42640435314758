import {FieldTemplate, Project, ProjectCategory, Role} from '@contractool/schema'
import React, {FC, useContext, useEffect} from 'react'

import {AppContext} from 'contexts'
import {CustomField} from 'components'
import {Form as CommonForm, FormContext} from 'components/Form'
import {Immutable as ImmutableTitle} from 'components/form/ProjectTitle'
import {Mutable as MutableTitle} from 'components/form/ProjectTitle'
import SupplierSelect from 'components/form/SupplierSelect'
import {useRequest} from 'hooks/useRequest'
import {getOptions, TeamRoleSelect} from 'utils/form'
import {translate} from 'utils/translations'
import ProjectFormContext from 'views/project/ProjectFormContext'

import {NdaInput} from 'integrations/dell_swp/components/project'

const Form: FC<{project: Project}> = ({project}) => {
  const {create, supplierTitle} = React.useContext(ProjectFormContext)
  const [categories] = useRequest<ProjectCategory[]>('/api/project-categories', [])
  const categoriesAsOptions = getOptions(categories)

  const {config} = useContext(AppContext)

  const fields = config.fields
    ? config.fields.filter((f: FieldTemplate) => !f.hidden && f.workflow === project.workflow)
    : []

  let groups: any = {}
  for (let f of fields) {
    if (f.group !== '') {
      groups[f.group] = f.group
    }
  }

  const formContext = useContext(FormContext)

  useEffect(() => {
    if (!create) {
      return
    }
    const parts = []
    const {values} = formContext
    if (values.fields.business_segment) {
      parts.push(formContext.values.fields.business_segment)
    }
    if (values.supplier_id) {
      parts.push(supplierTitle)
    }
    if (values.fields.request_type) {
      parts.push(formContext.values.fields.request_type)
    }
    if (values.fields.need_at) {
      parts.push(formContext.values.fields.need_at)
    }

    const title = parts.join(' ')

    if (values.title !== title) {
      formContext.handleChange('title', title)
    }
  }, [formContext, supplierTitle, create])

  useEffect(() => {
    if (formContext.values.fields.need_at) {
      const date1 = new Date()
      const date2 = new Date(formContext.values.fields.need_at)
      const diffTime = date2.getTime() - date1.getTime()
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      const needByDateJustificationField = config.fields.find(
        (field: any) => field.name === 'need_by_date_justification'
      )

      let days = 60 //!!! This 60 is hardcoded in ProjectRequest.php as well

      if (diffDays < days) {
        needByDateJustificationField.hidden = false
      } else {
        needByDateJustificationField.hidden = true
      }
    }
  }, [formContext, config.fields])

  return project ? (
    <>
      {config.roles && fields && (
        <>
          {create ? (
            <ImmutableTitle className="mb-8" />
          ) : (
            <MutableTitle className="mb-8" />
          )}
          <div className="divide-y divide-gray-100">
            <div className="py-8 w-full">
              {categories.length > 0 && (
                <CommonForm.Dropdown
                  name="category_id"
                  className="mb-6"
                  label={translate('Spend Category')}
                  options={categoriesAsOptions}
                  required={true}
                />
              )}
              <SupplierSelect required />
              {fields
                .filter((field: FieldTemplate) => field.group === '')
                .filter((field: FieldTemplate) => field.name === 'nda_number')
                .map((field: FieldTemplate) => (
                  <NdaInput
                    key={`${field.name}`}
                    field={field}
                    option="fields."
                    className="mt-6"
                  />
                ))}
              {fields
                .filter((f: FieldTemplate) => f.group === '')
                .filter((field: FieldTemplate) => field.name !== 'nda_number')
                .map((field: FieldTemplate) => (
                  <CustomField
                    key={`${field.name}`}
                    field={field}
                    option="fields."
                    className="mt-6"
                    required={
                      field.name === 'need_by_date_justification'
                        ? true
                        : false
                    }
                  />
                ))}
              {create && config.roles.map(
                (role: Role) =>
                  role.workflow === project.workflow &&
                    role.key !== 'admin' &&
                      role.key !== 'spectator' &&
                      role.key !== 'requestor' &&
                      role.key !== 'assignee' && (
                    <TeamRoleSelect
                      key={role.key}
                      role={role}
                      className="mt-6"
                    />
                  )
              )}
            </div>
            {Object.keys(groups).map((group: string) => {
              return (
                <div key={group}>
                  <h1 className="text-xl">{group}</h1>
                  <div className="py-8 w-full">
                    {fields
                      .filter((f: FieldTemplate) => f.group === group)
                      .map((field: FieldTemplate) => (
                        <CustomField
                          key={`${field.name}`}
                          field={field}
                          option="fields."
                          className="mt-6"
                        />
                      ))}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </>
  ) : null
}

export default Form
