import {ApprovalRule} from '@contractool/schema'
import React, {useCallback, useMemo} from 'react'
import {useParams, useHistory, Redirect} from 'react-router'

import {Button} from 'components/Button'
import {Icon} from 'components/Icon'
import {Menu, MenuItem} from 'components/Menu'
import {Modal} from 'components/Modal'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {tag} from 'views/settings/approval-rules/constants'
import Form from './Form'
import {Form as CommonForm} from 'components/Form'

export function Edit({approvalRules, onUpdate}: {
  approvalRules: ApprovalRule[]
  onUpdate: () => void
}) {
  const history = useHistory()
  const {id: idParam} = useParams<{id: string}>()
  const id = parseInt(idParam)
  const index = useMemo(() => approvalRules.findIndex(({id: approvalRuleId}) => id === approvalRuleId), [approvalRules, id])

  const goToSettingsPage = useCallback(() => history.push(`/settings/${tag}`), [history])
  const {success} = useToasts()

  const deleteApprovalRule = useCallback(() => {
    http.put(`/api/settings/${tag}`, {
      value: approvalRules.splice(index, 1)
    }).then(() => {
      onUpdate()
      success('The approval rule has been deleted.', {
        position: 'center'
      })
      goToSettingsPage()
    })
  }, [approvalRules, index, goToSettingsPage, onUpdate, success])

  const approvalRule = approvalRules[index]

  return approvalRule ? (
    <Modal
      heading={translate('Approval Rule Detail')}
      onClose={goToSettingsPage}
      contentClassName="h-124"
      corner={
        <Menu
          handle={({toggleMenu}) => (
            <div onClick={toggleMenu} className="flex items-center cursor-pointer">
              <Icon name="more_horiz" className="text-gray-600 cursor-pointer" size={6} />
            </div>
          )}
        >
          <MenuItem icon="remove_circle" onClick={deleteApprovalRule}>
            {translate('Delete')}
          </MenuItem>
        </Menu>
      }
    >
      <CommonForm
        initialValues={approvalRule}
        onSubmit={(approvalRule) => {
          approvalRules[index] = approvalRule

          return http.put(`/api/settings/${tag}`, {
            value: approvalRules
          })
        }}
        onSuccess={() => {
          onUpdate()
          success('Approval rules has been updated.', {
            position: 'center'
          })
          goToSettingsPage()
        }}
      >
        <Form approvalRule={approvalRule} />

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={history.goBack}>
            {translate('Cancel')}
          </Button>

          <CommonForm.Submit>{translate('Save Changes')}</CommonForm.Submit>
        </Modal.Footer>
      </CommonForm>
    </Modal>
  ) : <Redirect to="/settings" />
}
