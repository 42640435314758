import * as React from 'react'

import {Dropdown} from 'components/Dropdown'
// import {Select} from 'components/select';

interface IYearSelectProps {
    value: number | null;
    onChange: (year: number | null) => void;
    placeholder?: string;
}

const lastYear = (new Date()).getFullYear() - 1

const YearSelect: React.FC<IYearSelectProps> = ({value, onChange, placeholder}) => {
  const options = React.useMemo(() => {
    const options = []
    let year = lastYear
    while (year < lastYear + 5) {
      options.push({
        value: year,
        label: String(year)
      })
      year++
    }

    return options
  }, [])

  return (
    <Dropdown
      name={String(Math.random())}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      autocomplete={true}
    />
  )
}

export default YearSelect
