import React, {ReactNode, MouseEventHandler, FC} from 'react'
import {Icon, Icons} from './Icon'
import {Link as RouterLink} from 'react-router-dom'

export type Color = 'blue' | 'white' | 'yellow' | 'red' | 'teal';
type Size = 'regular' | 'small';

interface Props {
    color?: Color;
    size?: Size;
    disabled?: boolean;
    radius?: 'regular' | 'full';
    type?: 'button' | 'submit';
    title?: string;
    icon?: Icons;
    children?: ReactNode;
    className?: string;
    tabIndex?: number;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button: FC<Props> & {Link: typeof Link} = ({
  color = 'blue',
  size = 'regular',
  disabled = false,
  radius = 'regular',
  type = 'button',
  title,
  icon,
  children,
  className,
  onClick,
  tabIndex
}) => {
  const iconStyles = icon_styles({color, children})
  const classNames = styles({size, color, icon, children, disabled, radius, className})

  return (
    <button
      type={type}
      title={title}
      onClick={onClick}
      className={classNames}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {icon ? <Icon name={icon} size={6} className={iconStyles} /> : null}

      {children}
    </button>
  )
}

const Link: FC<
    Omit<Props, 'onClick' | 'disabled' | 'type' | 'tabIndex'> & {
        to: string;
    }
> = ({to, color = 'blue', size = 'regular', radius = 'regular', icon, children, className}) => {
  const iconStyles = icon_styles({color, children})
  const classNames = styles({size, color, icon, children, disabled: false, radius, className})

  return (
    <RouterLink to={to} className={classNames}>
      {icon ? <Icon name={icon} size={6} className={iconStyles} /> : null}

      {children}
    </RouterLink>
  )
}

Button.Link = Link

function size_styles(size: Size, hasIcon: boolean, hasChildren: boolean): string {
  if (hasIcon && !hasChildren) {
    return {
      regular: 'p-4',
      small: 'p-2'
    }[size]
  }

  if (hasIcon && hasChildren) {
    return {
      regular: 'pl-4 pr-6 py-4',
      small: 'pl-3 pr-6 py-3'
    }[size]
  }

  return {
    regular: 'px-6 py-4',
    small: 'px-6 py-3'
  }[size]
}

function color_styles(color: Color, disabled: boolean): string {
  if (disabled) {
    return 'bg-gray-000 text-gray-400 cursor-not-allowed'
  }

  return {
    'blue':
            'bg-blue-700 text-white border border-blue-700 hover:bg-blue-800 hover:border-blue-800 focus:outline-none focus:border-blue-700 focus:shadow-outline',
    'white':
            'bg-white text-gray-700 border border-gray-200 hover:bg-gray-000 hover:border-gray-000 focus:outline-none focus:border-blue-700 focus:shadow-outline',
    'yellow':
            'bg-yellow-800 text-white border border-yellow-800 hover:bg-yellow-900 hover:border-yellow-900 focus:outline-none focus:border-blue-700 focus:shadow-outline',
    'red':
            'bg-red-700 text-white border border-red-700 hover:bg-red-800 hover:border-red-800 focus:outline-none focus:border-blue-700 focus:shadow-outline',
    'teal':
            'bg-teal-700 text-white border border-teal-700 hover:bg-teal-800 hover:border-teal-800 focus:outline-none focus:border-blue-700 focus:shadow-outline'
  }[color]
}

function icon_styles({color, children}: Pick<Props, 'color' | 'children'>): string {
  const styles = ['inline']

  if (color === 'white') {
    styles.push('text-gray-600')
  }

  if (children) {
    styles.push('mr-3')
  }

  return styles.join(' ')
}

function styles({
  size = 'regular',
  color = 'blue',
  icon,
  children,
  disabled = false,
  radius,
  className
}: Props) {
  const sizeStyles = size_styles(size, !!icon, !!children)
  const colorStyles = color_styles(color, disabled)

  return `select-none inline-flex justify-center items-center leading-tighter transition ${
    radius === 'full' ? 'rounded-full' : 'rounded-lg'
  } ${sizeStyles} ${colorStyles} ${className}`
}
