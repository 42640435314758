import {Project, Report, Supplier, User} from '@contractool/schema'
import React, {useState, useEffect} from 'react'
import {Switch, Route, useHistory} from 'react-router-dom'

import {Modal} from 'components/Modal'
import {ProjectsList} from 'views/projects/Grid/ProjectsList'
import {EventEmitter} from 'utils/eventEmitter'
import {http} from 'utils/http'
import {useRequest} from 'utils/hooks/useRequest'
import {getOptions} from 'utils/form'
import {translate} from 'utils/translations'
import {Reports} from './components'
import {ReportDateRange} from './components'
import {Filters} from './interfaces'

const ReportsView = () => {
  const history = useHistory()

  const [projects, setProjects] = useState<Project[]>([])
  const [dateFilters, setDateFilters] = useState<Filters>({
    month: '',
    year: '',
    quarter: [],
    dateFrom: undefined,
    dateTo: undefined
  })

  const [reportsList] = useRequest<Report[]>('/api/reports', [])
  const [suppliers] = useRequest<Supplier[]>('/api/suppliers', [], {
    params: {page: 1, per_page: 9999999}
  })

  const [users] = useRequest<User[]>('/api/users', [], {
    params: {page: 1, per_page: 9999999}
  })

  EventEmitter.unsubscribe('report-projects')
  EventEmitter.unsubscribe('set-date-filter')
  EventEmitter.subscribe('set-date-filter', (data: any) => {
    setDateFilters(data)
  })
  EventEmitter.subscribe('report-projects', (data: any) => {
    data.month = dateFilters.month
    if (!data.quarter) {
      data.quarter = dateFilters.quarter
    }
    if (!data.year) {
      data.year = dateFilters.year
    }
    data.dateFrom = dateFilters.dateFrom
    data.dateTo = dateFilters.dateTo

    // @ts-ignore
    http.get<Project[]>('/api/reports/projects', data).then((data) => {
      setProjects(data.data)
      history.push('/reports/projects')
    })
  })

  useEffect(() => {
    const dispatchDateFilter = () => {
      if (
        (dateFilters.dateFrom && !dateFilters.dateTo) ||
                (!dateFilters.dateFrom && dateFilters.dateTo)
      ) {
        return
      }
      //Toto je event ktory refreshne kazdy graf
      EventEmitter.dispatch('report-date-filter', {
        month: dateFilters.month,
        quarter: dateFilters.quarter,
        year: dateFilters.year,
        dateFrom: dateFilters.dateFrom,
        dateTo: dateFilters.dateTo
      })
    }

    dispatchDateFilter()
  }, [dateFilters])

  const close = () => {
    history.push('/reports')
  }
  if (
    !reportsList ||
        reportsList.length === 0 ||
        !suppliers ||
        suppliers.length === 0 ||
        !users ||
        users.length === 0
  ) {
    return null
  }

  const suppliersAsOptions = getOptions(suppliers)
  const usersAsOptions = getOptions(users)

  const reports = (
    <Reports
      dateFilters={dateFilters}
      reports={reportsList}
      usersAsOptions={usersAsOptions}
      suppliersAsOptions={suppliersAsOptions}
    />
  )

  return (
    <div className="bg-gray-000 p-10">
      <div style={{maxWidth: '1000px'}}>
        <div className="flex justify-between">
          <h1 className="text-xl select-none mb-12 text-gray-800">
            {translate('Reports')}
          </h1>
          <div>
            <ReportDateRange filters={dateFilters}/>
          </div>
        </div>
        {reports}
      </div>
      <Switch>
        <Route path="/reports/projects" exact={true}>
          <Modal
            heading="Report detail"
            compact={true}
            corner={
              <div onClick={close} className="cursor-pointer text-blue-500">
                {translate('Close')}
              </div>
            }
            size="w-full"
            onClose={close}
          >
            <ProjectsList
              projects={projects}
              onRefresh={() => {
              }}
              loaded={true}
              onFavorite={() => {
              }}
            />
            <div className="flex justify-center pt-16">
              {/*<Pagination*/}
              {/*currentPage={pagination.currentPage}*/}
              {/*totalPages={pagination.totalPages}*/}
              {/*totalBlocks={7}*/}
              {/*onSelect={(page) => setParam('page', page)}*/}
              {/*/>*/}
            </div>
          </Modal>
        </Route>
      </Switch>
    </div>
  )
}

export default ReportsView
