import {Filter} from '@contractool/schema'
import React, {FC, ReactNode} from 'react'
import {Link, Switch, Route} from 'react-router-dom'

import {Button} from 'components/Button'
import {Icon} from 'components/Icon'
import {Modal} from 'components/Modal'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'

import {FilterEdit} from './FilterEdit'

export function Categories({
  filters,
  onRefresh,
  onClose,
  children
}: {
    filters: Filter[];
    onRefresh: (id?: number) => void;
    onClose: () => void;
    children: ReactNode;
}) {
  return (
    <Modal
      heading={translate('Manage categories')}
      isOpen={true}
      onClose={onClose}
      size="small"
      corner={
        <Link to="/projects/categories/new" className="flex text-blue-600 items-center">
          <Icon name="add" size={5} className="mr-2" />
          {translate('Create new')}
        </Link>
      }
      compact={true}
    >
      <ul>
        <li key="yours" className="flex items-center py-7 border-b text-gray-700">
          <Icon name="lock_outlined" size={6} className="mr-6 text-gray-500" />
          {translate('Yours')}
        </li>
        {filters.map((category: Filter) => (
          <Category key={category.id} category={category} onRefresh={onRefresh} />
        ))}
      </ul>
      <Modal.Footer className="flex justify-end">
        <Button color="blue" onClick={onClose}>
          {translate('Done')}
        </Button>
      </Modal.Footer>
      <Switch>
        <Route path="/projects/categories/new">{children}</Route>
        <Route path={'/projects/categories/:id/edit'}>
          <FilterEdit
            filters={filters}
            heading={translate('Edit category')}
            onSubmit={onRefresh}
          />
        </Route>
      </Switch>
    </Modal>
  )
}

const Category: FC<{category: Filter; onRefresh: (id?: number) => void}> = ({
  category,
  onRefresh
}) => {
  const {success} = useToasts()

  const remove = () => {
    http.delete(`/api/project-filters/${category.id}`).then(() => {
      onRefresh(category.id)
      success(`${translate('Category successfully removed')}.`)
    })
  }
  const update = () => {
    http.put(`/api/project-filters/${category.id}`, {
      is_visible: !category.is_visible
    }).then(() => {
      onRefresh(category.id)
      success(`${translate('Category visibility successfully changed')}.`)
    })
  }

  return (
    <li
      className="flex items-center justify-between py-7 border-b text-gray-700"
      data-cy={category.title}
    >
      <div className="flex">
        <Icon name="reorder" size={6} className="mr-6 text-gray-500" />
        {category.title}
      </div>
      <div className="flex space-x-3">
        <div onClick={remove} title={translate('Delete category')}>
          <Icon name="delete" size={6} className="text-gray-500 cursor-pointer" />
        </div>
        <div>
          <Link to={`/projects/categories/${category.id}/edit`} title="Edit category">
            <Icon name="edit" size={6} className="text-gray-500 cursor-pointer" />
          </Link>
        </div>
        <div onClick={update} title={translate('Toggle category')}>
          <Icon
            name="visibility"
            size={6}
            className={` cursor-pointer ${
              category.is_visible ? 'text-blue-700' : 'text-gray-500'
            }`}
          />
        </div>
      </div>
    </li>
  )
}
