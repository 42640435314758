import {ApprovalRule, RedFlag, Role, User, UserGroup} from '@contractool/schema'
import React, {FC, useContext, useMemo} from 'react'
import ReactDOM from 'react-dom'
import {Route, Switch, useHistory} from 'react-router'

import {RouteTabs, Tab} from 'components/Tabs'
import {Page} from 'components/Page'
import {Button} from 'components/Button'
import {AppContext} from 'contexts'
import {translate} from 'utils/translations'
import {useRequest} from 'hooks/useRequest'
import {tag as redFlagsTag} from 'views/settings/red-flags/constants'
import {tag as approvalRulesTag} from 'views/settings/approval-rules/constants'
import {New as RedFlagNew} from './red-flags/New'
import {Edit as RedFlagEdit} from './red-flags/Edit'
import {New as ApprovalRuleNew} from './approval-rules/New'
import {Edit as ApprovalRuleEdit} from './approval-rules/Edit'

export function SettingsPage() {
  const [redFlagsResponse, {refresh: refreshRedFlags}] = useRequest<RedFlag[]>(`/api/settings/${redFlagsTag}`, [])
  const redFlags = useMemo(() => redFlagsResponse ?? [], [redFlagsResponse])

  const [approvalRulesResponse, {refresh: refreshApprovalRules}] = useRequest<ApprovalRule[]>(`/api/settings/${approvalRulesTag}`, [])
  const approvalRules = useMemo(() => approvalRulesResponse ?? [], [approvalRulesResponse])

  return (
    <Page heading="Settings">
      <RouteTabs match="/settings/:tab">
        <Tab name="red-flags" heading="Red Flags" className="py-8">
          <div className="-mx-6">
            <RedFlagsList redFlags={redFlags} />
          </div>
        </Tab>

        <Tab name="approval-rules" heading="Approval Rules" className="py-8">
          <div className="-mx-6">
            <ApprovalRulesList approvalRules={approvalRules} />
          </div>
        </Tab>

        <Tab name="integrations" heading="Integrations" className="py-8">

        </Tab>

        <Tab name="api" heading="API" className="py-8">

        </Tab>
      </RouteTabs>

      <Switch>
        <Route path={`/settings/${redFlagsTag}/new`}>
          <RedFlagNew redFlags={redFlags} onCreate={refreshRedFlags} />
        </Route>

        <Route path={`/settings/${redFlagsTag}/:id/edit`}>
          <RedFlagEdit redFlags={redFlags} onUpdate={refreshRedFlags} />
        </Route>

        <Route path={`/settings/${approvalRulesTag}/new`}>
          <ApprovalRuleNew approvalRules={approvalRules} onCreate={refreshApprovalRules} />
        </Route>

        <Route path={`/settings/${approvalRulesTag}/:id/edit`}>
          <ApprovalRuleEdit approvalRules={approvalRules} onUpdate={refreshApprovalRules} />
        </Route>
      </Switch>
    </Page>
  )
}

interface IRedFlagsListProps {
  redFlags: RedFlag[]
}

const RedFlagsList: FC<IRedFlagsListProps> = ({redFlags}) => {
  const history = useHistory()

  const [users] = useRequest<User[]>('/api/users', [], {
    params: {id: redFlags.map((redFlag) => redFlag.userId)}
  })
  const {config} = useContext(AppContext)
  const buttonContainer = document.getElementById('page-header-right')

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Risk')}</th>
            <th>{translate('Project Role')}</th>
            <th>{translate('Group')}</th>
            <th>{translate('User')}</th>
          </tr>
        </thead>
        <tbody>
          {redFlags.map((redFlag) => (
            <tr
              key={redFlag.id}
              className="hover:bg-gray-000 cursor-pointer rounded"
              onClick={() => history.push(`/settings/red-flags/${redFlag.id}/edit`)}
            >
              <td>{redFlag.input}</td>
              <td className="text-gray-600">{redFlag.risk}</td>
              <td>{config.roles.find((role: Role) => redFlag.roleKey === role.key)?.label}</td>
              <td className="text-gray-600">{config.user_groups.find((group: UserGroup) => redFlag.groupKey === group.key)?.label}</td>
              <td>{users.find(user => redFlag.userId === user.id)?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {buttonContainer && ReactDOM.createPortal((
        <Button.Link to="/settings/red-flags/new" color="blue" icon="add">
          {translate('Add red flag')}
        </Button.Link>
      ), buttonContainer)}
    </>
  )
}

interface IApprovalRulesListProps {
    approvalRules: ApprovalRule[]
}

const ApprovalRulesList: FC<IApprovalRulesListProps> = ({approvalRules}) => {
  const history = useHistory()

  const [users] = useRequest<User[]>('/api/users', [], {
    params: {id: approvalRules.map((approvalRule) => approvalRule.userId)}
  })
  const {config} = useContext(AppContext)
  const buttonContainer = document.getElementById('page-header-right')

  return (
    <><table className="table">
      <thead>
        <tr>
          <th>{translate('Name')}</th>
          <th>{translate('Project Role')}</th>
          <th>{translate('Group')}</th>
          <th>{translate('User')}</th>
        </tr>
      </thead>
      <tbody>
        {approvalRules.map((approvalRule) => (
          <tr
            key={approvalRule.id}
            className="hover:bg-gray-000 cursor-pointer rounded"
            onClick={() => history.push(`/settings/approval-rules/${approvalRule.id}/edit`)}
          >
            <td>{approvalRule.input}</td>
            <td>{config.roles.find((role: Role) => approvalRule.roleKey === role.key)?.label}</td>
            <td className="text-gray-600">{config.user_groups.find((group: UserGroup) => approvalRule.groupKey === group.key)?.label}</td>
            <td>{users.find(user => approvalRule.userId === user.id)?.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {buttonContainer && ReactDOM.createPortal((
      <Button.Link to="/settings/approval-rules/new" color="blue" icon="add">
        {translate('Add approval rule')}
      </Button.Link>
    ), buttonContainer)}
    </>
  )
}
