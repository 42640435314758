import React, {useContext} from 'react'
import {NavLink, Link} from 'react-router-dom'
import {Icon, Icons} from '../Icon'
import {useLocalStorage} from '@rehooks/local-storage'
import {ReactComponent as Logo} from './../../assets/cequence.svg'
import {userPermissionRequest} from 'utils/wildcard'
import {GuardContext} from 'contexts'
import {translate} from 'utils/translations'

const ANIMATION_DURATION = 0.3

const sidebarStyle = {
  transition: `width ${ANIMATION_DURATION}s`
}

const sidebarLabelStyle = {
  transition: `opacity ${ANIMATION_DURATION}s`
}

export function Sidebar() {
  const [expanded, toggleExpanded] = useExpanded()
  const {user} = useContext(GuardContext)

  return (
    <div
      className={`border-r flex-shrink-0 h-full overflow-hidden ${
        expanded ? 'w-72' : 'w-20'
      }`}
      style={sidebarStyle}
    >
      <div className="flex flex-col h-full w-72">
        <div className="flex items-center flex-shrink-0 border-b h-20 px-6">
          <div onClick={() => toggleExpanded()} className="cursor-pointer">
            {expanded && <Icon name="dehaze" className="text-gray-600" size={8} />}
            {!expanded && (
              <Icon name="menu_open" className="text-gray-600 rotate-180" size={8} />
            )}
          </div>
          <Link
            to="/projects"
            className={expanded ? 'opacity-100' : 'opacity-0'}
            style={sidebarLabelStyle}
          >
            <Logo className="ml-4 w-36" />
          </Link>
        </div>

        <div className="h-full flex flex-col justify-between overflow-y-auto p-6">
          {userPermissionRequest(user, 'project.create') ? (
            <ul className="text-gray-600">
              <SidebarItem icon="add" to="/projects/new">
                {translate('New Project')}
              </SidebarItem>
            </ul>
          ) : (
            ''
          )}

          <div className="flex-1 flex items-center">
            <ul>
              {userPermissionRequest(user, 'project.view') ? (
                <SidebarItem icon="dashboard" to={'/projects'}>
                  {translate('Projects')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'reports.view') ? (
                <SidebarItem icon="bar_chart" to="/reports">
                  {translate('Reports')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'repository.view') ? (
                <SidebarItem icon="description" to="/repository">
                  {translate('Repository')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'library.view') ? (
                <SidebarItem icon="description" to="/library">
                  {translate('Library')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'clauses.view') ? (
                <SidebarItem icon="description" to="/clauses">
                  {translate('Clause library')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'calendar.view') ? (
                <SidebarItem icon="calendar_today" to="/calendar">
                  {translate('Calendar')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'suppliers.view') ? (
                <SidebarItem icon="contacts" to="/suppliers">
                  {translate('Suppliers')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'team.view') ? (
                <SidebarItem icon="people" to="/team">
                  {translate('Team')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'news.view') ? (
                <SidebarItem icon="feed" to="/news">
                  {translate('News')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'template-clause.view') ? (
                <SidebarItem icon="file" to="/template-clause">
                  {translate('Templates')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'template-clause.view') ? (
                <SidebarItem icon="file" to="/template-editor/1">
                  {translate('Template Editor')}
                </SidebarItem>
              ) : null}
              {userPermissionRequest(user, 'settings.view') ? (
                <SidebarItem icon="settings" to="/settings">
                  {translate('Settings')}
                </SidebarItem>
              ) : null}

              {/* ui lib should be accessible in dev environment
                                and CI as some cypress test run against it */}
              {(process.env.NODE_ENV !== 'production' ||
                                process.env.REACT_APP_CI) && (
                <SidebarItem icon="select_all" to="/ui">
                                    Design System
                </SidebarItem>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function SidebarItem<T extends Icons, C>({icon, to, children}: {icon: T; to: string; children: C}) {
  return (
    <li className="py-6">
      <NavLink
        to={to}
        className="flex items-center text-gray-600 hover:text-blue-700 focus:text-blue-700 focus:outline-none"
        activeClassName="text-blue-700"
      >
        <div className="flex justify-center w-8">
          <Icon name={icon} size={6} />
        </div>

        <div className="ml-6 leading-tighter select-none">{children}</div>
      </NavLink>
    </li>
  )
}

function useExpanded(): [boolean, () => void] {
  const [value, setValue] = useLocalStorage('_sidebar')

  const toggle = () => {
    setValue(value === 'expanded' ? 'collapsed' : 'expanded')
  }

  return [value === 'expanded', toggle]
}
