import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'

import {Icons} from 'components/Icon'
import {Color} from 'components/IconCircle'
import {BigRadioBox, RadioBoxContainer, SmallRadioBox} from 'components/RadioBox'
import {Tab, FormTabs} from 'components/Tabs'
import {useToasts} from 'hooks'

interface Data {
    icon?: Icons;
    title: string;
    desc?: string;
    span?: number;
    color?: Color;
    link?: string;
}
interface ExampleData {
    name: string;
    label?: string;
    type: 'BOXES' | 'LIST';
    data: Data[];
}

const exampleData: ExampleData[] = [
  {
    name: 'new-project',
    label: 'New Project',
    type: 'BOXES',
    data: [
      {
        icon: 'add_to_photos',
        title: 'New project',
        desc: 'Contract like MRA, MSA, ISA, SLA, PPDA or MPA',
        span: 2
      },
      {
        icon: 'add_to_photos',
        title: 'Amendment',
        desc: 'Simple Non-disclosure agreement contract',
        span: undefined
      },
      {
        icon: 'remove_circle',
        title: 'Termination',
        desc: 'Contracts that doesn’t fit into any previous category',
        span: undefined
      }
    ]
  },
  {
    name: 'organization',
    label: 'Organization',
    type: 'BOXES',
    data: [
      {
        icon: undefined,
        title: 'Production',
        desc: 'Categories like Materials, OEM Software, Packaging, Hard-drives...',
        span: undefined
      },

      {
        icon: undefined,
        title: 'Logistics',
        desc:
                    'Categories like Marcom, Business Process Outsourcing, Customer facing services...',
        span: undefined
      },

      {
        icon: undefined,
        title: 'Indirect',
        desc: 'Categories like Inbound, Outbound, Trade Compliance, Contract Logistics...',
        span: undefined
      }
    ]
  },
  {
    name: 'category',
    label: 'Category',
    type: 'LIST',
    data: [
      {
        icon: 'attach_money',
        title: 'Financial Services',
        color: 'lightgray'
      },
      {
        icon: 'lightbulb',
        title: 'Human Capital',
        color: 'yellow'
      },
      {
        icon: 'person_pin',
        title: 'Customer Facing Services',
        color: 'red'
      },
      {
        icon: 'group',
        title: 'HR Related Services',
        color: 'teal'
      },
      {
        icon: 'corporate',
        title: 'Global Real Estate and Facilities',
        color: 'violet'
      },
      {
        icon: 'drafts',
        title: 'Marketing Communication',
        color: 'orange'
      }
    ]
  },
  // {
  //   name: 'contract-type',
  //   label: 'Contract type',
  //   type: 'BOXES',
  //   data: [
  //     {
  //       icon: undefined,
  //       title: 'Governing',
  //       desc: 'Contracts like MRA, MSA, ISA, SLA, PPDA or MPA',
  //       span: undefined
  //     },
  //
  //     {
  //       icon: undefined,
  //       title: 'Sub-Agreement',
  //       desc: 'Contracts like SOW, Schedule or Order Form',
  //       span: undefined
  //     },
  //
  //     {
  //       icon: undefined,
  //       title: 'NDA',
  //       desc: 'Simple Non-disclosure agreement contract',
  //       span: undefined
  //     },
  //     {
  //       icon: undefined,
  //       title: 'Other',
  //       desc: 'Contracts that doesn’t fit into any previous category',
  //       span: undefined
  //     }
  //   ]
  // },
  {
    name: 'scope-of-services',
    label: 'Scope of services',
    type: 'BOXES',
    data: [
      {
        icon: undefined,
        title: 'Consulting',
        desc: undefined,
        span: undefined,
        link: '/projects/new/form'
      },

      {
        icon: undefined,
        title: 'Contingent Labor',
        desc: undefined,
        span: undefined,
        link: '/projects/new/form'
      },

      {
        icon: undefined,
        title: 'Finance',
        desc: undefined,
        span: undefined,
        link: '/projects/new/form'
      },
      {
        icon: undefined,
        title: 'Outsourcing',
        desc: undefined,
        span: undefined,
        link: '/projects/new/form'
      }
    ]
  }
]

export function FormTabsSection() {
  const history = useHistory()
  const [selected, setSelected] = useState(exampleData[0].name)

  const {info} = useToasts()
  const handleClick = (data: Data) => {
    if (data.link) {
      history.push(data.link + '?category_id=4&fields%5Bcommodity_ltwo%5D=Finance')
    } else {
      const id = exampleData.findIndex((i) => i.name === selected)
      if (id + 1 !== exampleData.length) {
        setSelected(exampleData[id + 1].name)
      }
      info(`You selected ${data.title}`)
    }
  }

  return (
    <FormTabs selected={selected}>
      {exampleData.map((item, index) => (
        <Tab
          key={item.name}
          name={item.name}
          heading={`${index + 1}. ${item.label}`}
          className="w-full h-130 "
        >
          {tabContent(item.type, item.data, handleClick, item.label)}
        </Tab>
      ))}
    </FormTabs>
  )
}

const tabContent = (
  type: 'BOXES' | 'LIST',
  data: Data[],
  handleClick: (d: Data) => void,
  label?: string
) => {
  switch (type) {
    case 'BOXES':
      const colSize = data.reduce((acc, d) => {
        const val = d.span ? d.span : 1

        return (acc = acc + val)
      }, 0)

      return (
        <RadioBoxContainer type="big" label={label} colSize={colSize}>
          {data.map((box: any, idx: number) => (
            <BigRadioBox
              key={`${box.title}${idx}`}
              icon={box.icon ? box.icon : undefined}
              title={box.title}
              description={box.desc}
              span={box.span ? box.span : 1}
              onClick={() => handleClick(box)}
            />
          ))}
        </RadioBoxContainer>
      )
    case 'LIST':
      return (
        <RadioBoxContainer
          type="big"
          className="px-6"
          colSize={2}
          rowSize={data.length / 2}
          label="Category"
        >
          {data.map((listItem, idx: number) => {
            const {icon, title, color} = listItem

            return icon && color ? (
              <SmallRadioBox
                key={`${title}${idx}`}
                icon={icon}
                title={title}
                color={color}
                onClick={() => handleClick(listItem)}
              />
            ) : null
          })}
        </RadioBoxContainer>
      )
  }
}
