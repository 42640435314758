import React, {FC, useMemo, useState} from 'react'
import {DocumentCategory, Project, Document, TemplateCategory, Template} from '@contractool/schema'
import {http} from 'utils/http'
import {Modal} from 'components/Modal'
import {Button} from 'components/Button'
import {ContractUpload} from './ContractPanel'
import {DocumentComponent} from 'components/documents/Document'
import {Tabs, Tab} from 'components/Tabs'
import {useRequest} from 'utils/hooks/useRequest'
import {useToasts} from 'hooks'
import {Checkbox} from 'components/Checkbox'
import {permissionRequest} from 'utils/wildcard'
import {translate} from 'utils/translations'

export function AddFiles({
  project,
  onUpdate,
  onClose,
  type
}: {
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
    type: string | null;
}) {

  const onSend = (file: {path: string; name: string}) => {
    return http.post<Document>(project.attachments_url, {file, type})
  }

  return (
    <>
      <Modal heading={translate('Add files')} onClose={onClose}>
        <label htmlFor="new-files" className="block leading-none select-none text-gray-500">
          {translate('Upload new files')}
        </label>
        <div id="new-files" className="pt-6 pb-8 mb-12 border-gray-200 border-b">
          <ContractUpload onSend={onSend} onUpload={onUpdate} singleFile={false} />
        </div>
        {permissionRequest(project.can, 'library.view') && (
          <Library project={project} onUpdate={onUpdate} onClose={onClose} type={type} />
        )}
      </Modal>
    </>
  )
}

const CheckboxDocument: FC<{
    project: Project;
    document: Document;
    selectedFiles: string[];
    onChange: (values: string[]) => void;
}> = ({project, document, selectedFiles, onChange}) => {
  return (
    <Checkbox
      name={`${document.title}`}
      value={document.id.toString()}
      selectedValues={selectedFiles}
      onChange={(values) => onChange(values)}
      childsClassName="w-full"
    >
      <DocumentComponent
        document={document}
        change={false}
        deletable={false}
        download={false}
        modalUrl={`projects/${project.id}`}
      />
    </Checkbox>
  )
}

const Library: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
    type: string | null;
}> = ({project, onUpdate, onClose, type}) => {
  const [categories] = useRequest<DocumentCategory[]>('/api/library/categories', [])
  const [documents] = useRequest<Document[]>('/api/library', [])

  const [templateCategories] = useRequest<TemplateCategory[]>('/api/template-categories', [])
  const [templates] = useRequest<Template[]>('/api/templates', [], {
    params: {
      paginate: false,
      sortBy: 'name',
      sortDirection: 'asc'
    }
  })

  const initiallySelectedTemplateIds = useMemo(() => project.templates.map(({id}) => id), [project])
  const [selectedTemplateIds, setSelectedTemplateIds] = useState(initiallySelectedTemplateIds)
  const selectedTemplateIdStrings = useMemo(() => selectedTemplateIds.map(id => `t${id}`), [selectedTemplateIds])

  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const {success, error} = useToasts()
  const addFiles = async () => {
    const arrLength = selectedFiles.length

    if (!arrLength) return

    selectedFiles.forEach((id, index) => {
      http.post<Document>(project.library_attachments_url, {
        document_id: parseInt(id),
        type: type
      }).then(() => {
        success(
          `${translate('Document :file was successfully added to project', {
            file: `${documents.find((doc) => doc.id === parseInt(id))?.title}`
          })}.`
        )
        if (index === arrLength - 1) {
          onUpdate()
        }
      })
    })
  }

  const handleChange = (templateIdStrings: string[]) => {
    setSelectedTemplateIds(templateIdStrings.map((idString: string) => Number(idString.slice(1))))
  }

  const addTemplates = async () => {
    const idsToAttach = selectedTemplateIds.filter(id => !initiallySelectedTemplateIds.includes(id))
    const idsToDetach = initiallySelectedTemplateIds.filter(id => !selectedTemplateIds.includes(id))

    try {
      await http.post(project.templates_url, {
        attach: idsToAttach,
        detach: idsToDetach
      })
      onUpdate()
      success(translate('Templates were successfully synced to project.'))
    } catch (event) {
      console.log(event)
      error(`${translate('Error in syncing project templates')}.`)
    }
  }

  const uploadItems = async () => {
    addFiles()
    if (!type) {
      await addTemplates()
    }
    onUpdate()
  }

  return (
    <form>
      <Tabs selected="filesLibrary" className="bg-white px-5">
        <Tab
          name="filesLibrary"
          heading={translate('Files Library')}
          className="pt-4 bg-white p-5"
        >
          <label className="block leading-none select-none text-gray-500 pb-6">
            {translate('Add from library')}
          </label>
          <Tabs selected="uncategorized" className="bg-white px-5">
            <Tab
              name="uncategorized"
              heading={translate('Uncategorized')}
              className="pt-4 bg-white p-5"
            >
              {documents
                .filter((document) => !document.category_id)
                .map((document, index) => {
                  return (
                    <CheckboxDocument
                      key={`${document.title}${index}`}
                      project={project}
                      document={document}
                      onChange={setSelectedFiles}
                      selectedFiles={selectedFiles}
                    />
                  )
                })}
            </Tab>
            {categories.map((category, index) => {
              return (
                <Tab
                  key={'document-category' + index}
                  name={'category' + index}
                  heading={category.title}
                  className="pt-4 bg-white p-5"
                >
                  {documents
                    .filter((document) => document.category_id === category.id)
                    .map((document, index) => (
                      <CheckboxDocument
                        key={`${document.title}${index}`}
                        project={project}
                        document={document}
                        onChange={setSelectedFiles}
                        selectedFiles={selectedFiles}
                      />
                    ))}
                </Tab>
              )
            })}
          </Tabs>
        </Tab>
        {permissionRequest(project.can, 'template-clause.view') && (
          <Tab
            name="templates"
            heading={translate('Templates')}
            className="pt-4 bg-white p-5"
          >
            <label className="block leading-none select-none text-gray-500 pb-6">
              {translate('Add from Templates')}
            </label>
            <Tabs selected="all" className="bg-white px-5">
              <Tab
                name="all"
                heading={translate('All')}
                className="pt-4 bg-white p-5"
              >
                {templates
                //.filter((template) => !template.id)
                  .map((template, index) => (
                    <CheckboxTemplate
                      key={`${template.name}${index}`}
                      project={project}
                      template={template}
                      onChange={handleChange}
                      selectedTemplateIdStrings={selectedTemplateIdStrings}
                    />
                  ))}
              </Tab>
              {templateCategories.map((category, index) => {
                return (
                  <Tab
                    key={'document-category' + index}
                    name={'category' + index}
                    heading={category.name}
                    className="pt-4 bg-white p-5"
                  >
                    {templates
                      .filter((template) => template.categories.map(c => c.id).includes(category.id))
                      .map((template, index) => {
                        return (
                          <CheckboxTemplate
                            key={`${template.name}${index}`}
                            project={project}
                            template={template}
                            onChange={handleChange}
                            selectedTemplateIdStrings={selectedTemplateIdStrings}
                          />
                        )
                      })}
                  </Tab>
                )
              })}
            </Tabs>
          </Tab>)}
      </Tabs>

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Button color="blue" onClick={uploadItems}>
          {translate('Add Selected')}
        </Button>
      </Modal.Footer>
    </form>
  )
}

const CheckboxTemplate: FC<{
    project: Project;
    template: Template;
    selectedTemplateIdStrings: string[];
    onChange: (values: string[]) => void;
}> = ({project, template, selectedTemplateIdStrings, onChange}) => {

  return (
    <Checkbox
      name={`${template.name}`}
      value={`t${template.id}`}
      selectedValues={selectedTemplateIdStrings}
      onChange={onChange}
      childsClassName="w-full"
    >
      <div className="border-b border-gray-100 py-7 flex" key={template.id}>
        <div className="flex">
          {project && <div className="leading-tighter pl-4 text-gray-700">{template.name}</div>}
        </div>
      </div>
    </Checkbox>
  )
}
