import Downshift, {GetInputPropsOptions} from 'downshift'
import {Placement} from 'popper.js'
import * as React from 'react'
import {createPortal} from 'react-dom'
import {Manager, Popper, Reference} from 'react-popper'

import {Icon} from 'components/Icon'
import {Option} from 'components/select'

function InputSelect<T = string | number | boolean>({
  name,
  inputVal, // value injection
  onInputChange,
  onInputBlur,
  placeholder,
  options,
  selectedItem,
  onStateChange,
  hasError = false,
  usePortal = true,
  autoFocus = false,
  dropdownPlacement = 'bottom-start',

  renderInput = DefaultRenderInput
}: {
    name: string;
    inputVal: string;
    onInputChange: (value: string) => void,
    onInputBlur: () => void,
    onStateChange: (options: any) => void;
    options: Option<T>[],
    selectedItem: Option<T> | null,
    placeholder?: string;
    hasError?: boolean;
    usePortal?: boolean;
    autoFocus?: boolean;
    dropdownPlacement?: Placement;

    renderInput?: React.FC<RenderInputProps<T>>;
}): React.ReactElement {
  const refDownshift = React.useRef<any>()

  function render(content: React.ReactNode): React.ReactNode {
    return usePortal ? createPortal(content, document.body) : content
  }

  return (
    <Manager>
      <Downshift
        selectedItem={selectedItem}
        onStateChange={onStateChange}
        ref={refDownshift}
      >
        {({
          isOpen,

          openMenu,
          toggleMenu,

          selectedItem,
          highlightedIndex,

          getMenuProps,
          getItemProps,
          getInputProps
        }) => (
          <div id={name}>
            <Reference>
              {({ref}) => (
                <div ref={ref}>
                  {renderInput({
                    name,
                    value: inputVal,
                    onChange: onInputChange,
                    onBlur: onInputBlur,
                    ref,
                    selectedItem,
                    placeholder,
                    hasError,
                    isOpen,
                    openMenu,
                    toggleMenu,
                    getInputProps,
                    autoFocus
                  })}
                </div>
              )}
            </Reference>

            {isOpen && render(
              <Popper
                modifiers={{
                  hide: {enabled: false},
                  preventOverflow: {enabled: false}
                }}
                placement={dropdownPlacement}
              >
                {({ref, style, placement}) => (
                  <div
                    ref={ref}
                    style={style}
                    data-placement={placement}
                    className="bg-white rounded-lg shadow-lg py-2"
                  >
                    <ul
                      {...getMenuProps()}
                      className="max-h-66 overflow-auto px-2"
                    >
                      {options.map(
                        (item, index) => {
                          const highlighted =
                                                highlightedIndex === index
                          const selected =
                                                selectedItem?.value === item.value

                          return (
                            <li
                              {...getItemProps({
                                key:
                                                            String(item.value) +
                                                            name +
                                                            index,
                                index,
                                item
                              })}
                              className={`px-6 py-3 leading-tighter rounded cursor-pointer ${
                                highlighted ? 'bg-gray-100' : ''
                              } ${
                                selected ? 'text-blue-500' : ''
                              }`}
                            >
                              {item.label}
                            </li>
                          )
                        }
                      )}
                    </ul>
                  </div>
                )}
              </Popper>
            )}
          </div>
        )}
      </Downshift>
    </Manager>
  )
}

interface RenderInputProps<T> {
    name: string;
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    ref: React.Ref<any>;
    selectedItem: Option<T> | null;
    placeholder: string | undefined;
    hasError: boolean;
    isOpen: boolean;
    openMenu: () => void;
    toggleMenu: () => void;
    getInputProps: <T>(options?: T | undefined) => T & GetInputPropsOptions;
    autoFocus?: boolean;
}

function DefaultRenderInput<T>({
  name,
  value,
  onChange,
  onBlur,
  hasError,
  placeholder,
  isOpen,
  openMenu,
  toggleMenu,
  getInputProps,
  autoFocus
}: RenderInputProps<T>) {
  return (
    <div className={`relative dropdown-${name}`}>
      <input
        type="search"
        className={`h-16 py-5 w-full leading-tighter outline-none focus:outline-none focus:border-blue-700 text-gray-700 border-b cursor-pointer ${
          hasError ? 'border-red-700' : 'border-gray-200'
        }`}
        style={{backgroundColor: 'transparent'}}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...getInputProps({
          onClick: () => {
            toggleMenu()
          },
          onKeyDown: (e) => {
            switch (e.key) {
              case 'Enter':
                if (!isOpen) {
                  openMenu()
                }
                break
            }
          }
        })}
        onChange={(e: any) => onChange(e.target.value)}
        onBlur={onBlur}
        value={value}
      />

      <button
        onClick={() => toggleMenu()}
        className="absolute w-4 h-16 top-0 right-0 flex items-center justify-center focus:outline-none"
        tabIndex={-1}
      >
        <Icon
          name="triangle"
          className={`text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
          size={2}
        />
      </button>
    </div>
  )
}

export default InputSelect
