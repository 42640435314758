import React, {useContext, useCallback, memo} from 'react'
import {Project} from '@contractool/schema'
import {Form} from 'components/Form'
import {Tab, Group} from '../../../interfaces'
import ProjectContext from '../../../../../../../ProjectContext'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {Button} from 'components/Button'
import {Modal} from 'components/Modal'
import {useToasts} from 'hooks'

const MemberModalForm: React.FC<{
    memberKey: string;
    children: React.ReactElement;
    group: Tab | Group;
    closeModal: () => void;
}> = ({memberKey, children, group, closeModal}) => {
  const {resource: project, refresh} = useContext(ProjectContext)

  const initialValues =
        project.meta && project.meta.widget_fields && project.meta.widget_fields[memberKey]
          ? project.meta.widget_fields[memberKey].values
          : {}

  const handleSubmit = useCallback(
    (memberValues) => {
      project.meta = project.meta || {}
      project.meta.widget_fields = {
        ...project.meta.widget_fields,
        [memberKey]: {
          export_url:
                        ((project.meta.widget_fields || {})[memberKey] || {}).export_url || '',

          values: memberValues
        }
      }
      let payload = JSON.parse(JSON.stringify(project))
      delete payload.logs_full
      delete payload.parent
      delete payload.children
      delete payload.child
      delete payload.team

      return http.put<Project>(project.url, payload)
    },
    [project, memberKey]
  )

  const {success} = useToasts()
  const handleSuccess = useCallback(() => {
    success(`${translate('Group :name was updated successfully', {name: group.name})}`)
    refresh()
    closeModal()
  }, [closeModal, group.name, success, refresh])

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} onSuccess={handleSuccess}>
      {children}
      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={closeModal}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  )
}

const PureMemberModalForm = memo(MemberModalForm)

export default PureMemberModalForm
