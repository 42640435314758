import React, {FC} from 'react'
import {Project} from '../../../../../_common/schema'
import {Confirmation} from '../../../components/Confirmation'
import {MenuItem} from '../../../components/Menu'
import {http} from '../../../utils/http'
import {translate} from 'utils/translations'
import {useToasts} from 'hooks'
import {useHistory} from 'react-router-dom'

export const Amend: FC<{project: Project}> = ({project}) => {
  const {success} = useToasts()
  const history = useHistory()

  return (
    <Confirmation
      onConfirm={() => {
        http.post(`api/projects/${project.id}/amend`).then((data: any) => {
          console.log('data.data', data)
          history.push(`/projects/${data.data.id}`)
          success(`${translate('Amendment created')}.`)
        })
      }}
      trigger={({onClick}) => (
        <MenuItem
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
        >
          {translate('Amend')}
        </MenuItem>
      )}
      heading={`${translate('Amend')}?`}
      buttonText={`${translate('Yes, amend')}`}
      color="yellow"
    >
      {translate('Are you sure you want to amend this project?')}
    </Confirmation>
  )
}
