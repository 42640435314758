import React, {useCallback, useContext} from 'react'
import {Button} from '../components/Button'
import {Form} from '../components/Form'
import {http} from '../utils/http'
import {User} from '@contractool/schema'
import {Tabs, Tab} from '../components/Tabs'
import {AuthContext, GuardContext} from 'contexts'
import {useToasts} from 'hooks'
import {Modal} from '../components/Modal'
import {timezones} from '../utils/timezones'
import {translate} from 'utils/translations'
import {AvatarUpload} from '../components/Avatar'
import {useHistory} from 'react-router-dom'

export function Profile() {
  const {success} = useToasts()
  const {update: updateUser} = useContext(AuthContext)
  const {user} = useContext(GuardContext)

  const history = useHistory<{prevLocation: string}>()

  const onUpdate = useCallback(
    (user) => {
      updateUser(user)
    },
    [updateUser]
  )

  const onCancel = useCallback(() => {
    if (history.location.state === undefined) {
      history.push('/')
    } else {
      history.push(history.location.state.prevLocation)
    }
  }, [history])

  return (
    <Modal
      compact={true}
      onClose={onCancel}
      heading={translate('Profile')}
      contentClassName="h-149"
    >
      <div className="flex items-center mb-11">
        <div>
          <AvatarUpload
            user={user}
            onUpload={(user) => {
              success(`${translate('Your avatar was successfully changed')}.`)
              onUpdate(user)
            }}
            className="w-32 h-32"
          />
        </div>

        <div className="ml-11">
          <h2 className="text-xl">{user.name}</h2>
          <p className="text-gray-600">{user.type}</p>
        </div>
      </div>

      <Tabs>
        <Tab name="general" heading={translate('General')} className="pt-8">
          <Form
            initialValues={user}
            onSubmit={(values) => http.put<User>('/api/auth/profile', values)}
            onSuccess={(user: User) => {
              success(`${translate('Your profile was successfully updated')}.`)
              onUpdate(user)
            }}
          >
            <Form.TextInput
              name="name"
              label={translate('Name')}
              className="mb-6"
              autoFocus={true}
            />
            <Form.TextInput name="email" label="Email" className="mb-6" />
            <Form.Dropdown
              name="timezone"
              label={translate('Timezone')}
              options={timezones}
              autocomplete
            />

            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Cancel')}
              </Button>

              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </Form>
        </Tab>
        <Tab name="password" heading={translate('Password')} className="pt-8">
          <Form
            initialValues={{password: '', password_confirmation: ''}}
            onSubmit={(values) => http.put('/api/auth/password', values)}
            onSuccess={(user: User) => {
              success(`${translate('Your password was successfully updated')}.`)
              onUpdate(user)
            }}
            clearAtSuccess={true}
          >
            <Form.TextInput
              name="current_password"
              type="password"
              label={translate('Current password')}
              className="mb-6"
              autoFocus={true}
            />
            <Form.TextInput
              name="password"
              type="password"
              label={translate('Password')}
              className="mb-6"
            />
            <Form.TextInput
              name="password_confirmation"
              type="password"
              label={translate('Confirm password')}
            />

            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Cancel')}
              </Button>

              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </Form>
        </Tab>
      </Tabs>
    </Modal>
  )
}
