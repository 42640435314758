import React, {FC, ReactNode} from 'react'

export const Field: FC<{
    name: string;
    label?: string;
    legend?: string;
    className?: string;
    errorMessage?: string;
    required?: boolean;
    right?: ReactNode;
}> = ({name, children, label, legend, errorMessage, className, required = false, right}) => {
  return (
    <div className={className}>
      <div className="hover:bg-gray-150 min-h-20 p-2 pb-4 rounded">
        <div className="flex justify-between">
          {label && (
            <label
              htmlFor={name}
              className={`block leading-normal select-none ${
                errorMessage ? 'text-red-700' : 'text-gray-600'
              }`}
            >
              {label}
              {required && <span className="ml-1 text-red-700">*</span>}
              {legend && <div className="text-gray-500 text-sm">{legend}</div>}
            </label>
          )}
          {right && <div className="leading-normal select-none">{right}</div>}
        </div>

        <div>{children}</div>

        {errorMessage && (
          <p className="pt-2 text-sm text-red-700" role="alert">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  )
}
