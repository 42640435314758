import {Configuration, User} from '@contractool/schema'
import {useLocalStorage} from '@rehooks/local-storage'
import Echo from 'laravel-echo'
import 'pusher-js'
import React, {FC, useContext, useState} from 'react'

import {AppContext, AuthContext, PusherContext} from 'contexts'
import {SamlRouter} from 'components'
import {LS_USER_KEY, LS_PROJECTS_QUERY_KEY} from 'data'
import {useRequest} from 'hooks/useRequest'
import {registerErrorResponseInterceptor} from 'utils/http'
import {init as initTranslations, getFetching, getReadiness} from 'utils/translations'

function App() {
  const [,, unsetUserInLocalStorage] = useLocalStorage<User>(LS_USER_KEY)

  const [loaded, setLoaded] = useState(false)
  const [config, {refresh}] = useRequest<Configuration | undefined>(
    '/api/configuration',
    undefined,
    {},
    setLoaded
  )

  // needs to be called synchronously (not within a useEffect)
  registerErrorResponseInterceptor((response) => {
    if (response?.status === 401) {
      unsetUserInLocalStorage()
      if (response.data.data.redirect) {
        setLoaded(false)
        window.location.href = response.data.data.redirect
      } else {
        setLoaded(true)
      }
    }
  })

  if (!getFetching() && !getReadiness()) {
    initTranslations()
  }

  const context = {
    loaded,
    config,
    refresh
  }

  return (
    <AppContext.Provider value={context}>
      {loaded ? (
        <PusherContextProvider>
          <AuthContextProvider>
            <SamlRouter />
          </AuthContextProvider>
        </PusherContextProvider>
      ) : (
        <div className="text-2xl p-25">Loading, please wait...</div>
      )}
    </AppContext.Provider>
  )
}

const AuthContextProvider: FC = ({children}) => {
  const [userInLocalStorage, setUserInLocalStorage, unsetUserInLocalStorage] = useLocalStorage<User>(LS_USER_KEY)
  const [,, unsetProjectsQueryInLocalStorage] = useLocalStorage<string>(LS_PROJECTS_QUERY_KEY)

  const [user, setUser] = React.useState(userInLocalStorage)
  const updateUser = (user: User) => {
    setUserInLocalStorage(user)
    setUser(user)
  }
  const {refresh} = useContext(AppContext)

  const value = {
    user,
    loggedIn: Boolean(user),
    login(user: User) {
      updateUser(user)
      refresh()
    },
    logout() {
      unsetProjectsQueryInLocalStorage()
      unsetUserInLocalStorage()
      setUser(null)
    },
    update(user: User) {
      updateUser(user)
    }
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

const PusherContextProvider: FC = ({children}) => {
  const {config} = useContext(AppContext)

  let echo = {} as Echo
  if (config) {
    console.log('Pusher establishment')
    echo = new Echo({
      broadcaster: 'pusher',
      key: config.config.pusher_key,
      encrypted: true,
      cluster: config.config.pusher_cluster,
      authEndpoint: '/api/broadcasting/auth'
    })
  }

  return (
    <PusherContext.Provider value={echo}>
      {children}
    </PusherContext.Provider>
  )
}

export default App
